<template>
  <div>
    <div v-if="uploadedImages.length === 0">暂未上传图片</div>
    <div class="uploadedImages" v-else>
      <span v-for="(img, idx) in uploadedImages" :key="img.path" style="line-height: 15px">
        <img v-real-img="img.url" :style="{ maxWidth: width + 'px', maxHeight: height + 'px' }" />
        <br />
        <Button icon="ios-open-outline" @click="openImage(img.url)">打开</Button>
        <Button icon="ios-trash-outline" @click="removeUploadImage(img.path)">删除</Button>
        <Select
          v-if="enableCrop"
          style="width:100px"
          @on-change="changeImageSize"
          placeholder="裁切方案"
          :value="idx + ',' + currentSuffix + ',' + img.url"
        >
          <Option
            v-for="size in sizes[img.path]"
            :key="size.name"
            :value="idx + ',' + size.suffix + ',' + size.imageUrl"
          >
            {{ size.name }}
          </Option>
        </Select>
      </span>
    </div>
    <Upload
      v-if="showUpload"
      :headers="{ 'X-Auth': token }"
      action="/backstage/media/uploadImages"
      name="images"
      :format="['jpg', 'jpeg', 'png']"
      :max-size="2048"
      :before-upload="filesReadyToUpload"
      :on-success="filesUploaded"
      :show-upload-list="false"
    >
      <Button icon="ios-cloud-upload-outline">上传</Button>
    </Upload>
  </div>
</template>

<script>
import { queryMediaUrls, queryMediaSizes } from "@/api"

export default {
  name: "ImageUploader",
  props: {
    defaultImages: { type: Array, default: () => [] },
    maxFiles: { type: Number, default: 1 },
    width: { type: Number, default: 96 },
    height: { type: Number, default: 96 },
    enableCrop: { type: Boolean, default: false },
    defaultCrop: { type: String, default: "tiny" },
  },
  data() {
    return {
      uploadedImages: [],
      token: sessionStorage.getItem("token"),
      sizes: {},
    }
  },
  async mounted() {},
  watch: {
    defaultImages: {
      immediate: true,
      async handler(newV) {
        if (!newV || !newV.length) {
          this.uploadedImages = []
          this.sizes = {}
          return
        }
        const mediaUrls = await queryMediaUrls(newV, this.currentSuffix)
        const images = []
        const allPaths = []
        Object.keys(mediaUrls).forEach(path => {
          allPaths.push(path)
          images.push({ path, url: mediaUrls[path], suffix: this.currentSuffix })
        })
        this.uploadedImages = images
        if (this.enableCrop) {
          this.sizes = await queryMediaSizes(allPaths)
        }
      },
    },
  },
  computed: {
    showUpload() {
      return this.uploadedImages.length < this.maxFiles
    },
    currentSuffix() {
      if (this.defaultCrop == "original") {
        return ""
      }
      return this.defaultCrop
    },
  },
  methods: {
    removeUploadImage(key) {
      this.uploadedImages = this.uploadedImages.filter(image => image.path != key)
      this.$emit("removedUploaded", this.uploadedImages, key)
    },
    filesReadyToUpload() {
      if (1 + this.uploadedImages.length > this.maxFiles) {
        this.$Message.error(`最多可以上传${this.maxFiles}张图片`)
        return false
      }
    },
    filesUploaded(response) {
      if (1 + this.uploadedImages.length > this.maxFiles) {
        this.$Message.error(`最多可以上传${this.maxFiles}张图片, 多余的图片将被忽略`)
        return
      }
      this.uploadedImages = this.uploadedImages.concat(response.data)
      this.uploadedImages = this.uploadedImages.map(item => {
        item.suffix = this.defaultCrop
        return item
      })
      this.$emit("uploadSucceed", this.uploadedImages)
    },
    changeImageSize(item) {
      const [idx, suffix, url] = item.split(",")
      const index = Number.parseInt(idx)
      this.uploadedImages[index].suffix = suffix
      this.uploadedImages[index].url = url
      this.$emit("uploadSucceed", this.uploadedImages)
    },
    openImage(url) {
      window.open(url, "_blank")
    },
  },
}
</script>
